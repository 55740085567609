#profilePic{
    mask-image: url('./Assets/hexagon.png');
    -webkit-mask-image: url('./Assets/hexagon.png');
    mask-mode: alpha;
    -webkit-mask-mode: alpha;
    object-fit: cover;
    height: 100%;
    width: 100%;
    mask-size:contain;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
}

.profilePicContainer{
    position: relative;
    margin: auto;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 350px;
}

.intro-text{
    align-self: center;
    display: flex;
    flex-direction: column;
    font-size: 1.1em;
    padding: 0px 5%;
}

.firstRow{
    margin-top: 150px;
    margin-bottom: 100px;
}

#hello-greeting{
    font-size: 1.5em;
}

hr{
    height: 1.5px;
    border: none;
    background-color: white;
    opacity: 1;
    color: white;
}

.about-headings{
    font-size: 1em;
    font-family: 'Roboto Mono' , monospace;
    line-height: 0.4;
}

.about-columns-padding{
    padding: 4% 5%;
}

.about-heading2{
    color: #F39A68;
    font-size: 1.5em;
    letter-spacing: 0.05em;
    line-height: 3;
}

.about-columns{
    line-height: 1.65;
}


@media only screen and (max-width: 768px){
    .profilePicContainer{
        margin-bottom: 50px;
    }
    
    .firstRow{
        margin-bottom: 50px;
    }
}