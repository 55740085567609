.myCard{
    display: flex;
    flex-direction: row;
    background-color: #1F3038;
    padding: 5%;
    gap: 30px;
    margin-top: 0;
    margin-left: -100%;
    margin-bottom: 0;
    margin-right: 0;
    transition-duration: 350ms;
    transition-property: all;
    transition:cubic-bezier();
}

.myCardContainer:hover .myCard{
    margin-top: -20px;
    margin-left: calc(-100% - 20px);
    margin-bottom: 20px;
    margin-right: 20px;
}

.myCardContainer{
    margin: 50px 0px;
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.border{
    color:white;
    border: 1px;
    width: 100%;
    height: auto;
}

.myCard, .border{
    box-sizing: border-box;
    width: 100%;
    flex: none;
}

.myCardContainer{
    color:white;
    text-decoration: none;
}

.cardInfo{
    display: flex;
    position: relative;
    flex-direction:column;
    flex: 1;
    width: 45%;
}

.coverImage{
    width: 55%;
    position: relative;
}

.coverImage img{
    width: 100%;
    height: 100%;
    max-height: 300px;
    flex: 1;
    object-fit: cover;
    border-radius: 8px;
    margin: 0px;
}

.pills{
    display: flex;
    flex-direction: row;
    margin-top: auto;
    gap: 15px;
    flex-wrap: wrap;
}

.pill{
    border-width: 1px;
    color: white;
    border-style: dashed;
    padding: 7px 15px;
}

.pill p{
    margin-bottom: 0px;
}

.cardTitle{
    margin-bottom: 25px;
    font-family: 'Roboto Mono';
    font-weight: 500;
    font-size: 1.3em;
}

@media only screen and (max-width: 700px){
    .myCard{
        flex-direction: column;
        max-width: 525px;
        margin-left: calc(max(-100%, -525px))
    }

    .border{
        max-width: 525px;
    }

    .myCardContainer:hover .myCard{
        margin-left: calc(max(-100%, -525px) - 20px);
    }
    
    .coverImage{
        padding: 5% 0% 0%;
        width: 100%;
    }

    .cardInfo{
        width: 100%
    }
}

