.main-horizontal-padding{
    padding: 5%;
}

.main-vertical-padding{
    padding-top: 180px;
    padding-bottom: 0px;
}

#decoration{
    position: sticky;
    top: 0;
    right: 50px;
    width: 100%;
    padding-right: 0;
    margin: 0;
}

.button-collection{
    gap: 30px;
}


@media only screen and (max-width: 992px){
    .main-width{
        width: 95%;
        margin: 80px 2%;
    }

    #decoration{
        display: none;
    }
}

@media only screen and (max-width: 700px){
    .button-collection{
        flex-direction: column;
        flex: 1;
        gap: 0px;
        align-content: center;
    }

    .name-banner-container{
        display: flex;
        flex-direction: column;
        margin: auto;
    }
}