body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: Optima, 'Cormorant Garamond', 'serif';
  color: white;
}

h1{
  color:orange;
  font-size: 1em;
  font-family: 'Roboto Mono', monospace;
  line-height: 3;
}

h2{
  font-size: 1.5em;
  font-weight: bolder;
  line-height: 2;
}

img{
  margin: 20px 0px 70px;
}

p{
  line-height: 1.7;
  font-family: 'Poppins';
}

ul, li{
  font-family: "Poppins";
}