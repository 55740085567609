.hero{
    width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover;
    margin-top: 80px;
    margin-bottom: 20px;
}

.description{
    margin-top: 30px;
    margin-bottom: 30px;
}

.descriptionDetails{
    font-size: 1.1em;
    font-weight: 500;
}

.descriptionHeading{
    font-size: 0.9em;
    font-family: 'Roboto Mono', monospace;
}

.projectTitle{
    font-size: 3em;
    font-variant: small-caps;
    font-family: 'Optima';
    margin-bottom: 0px;
}