.callout{
    margin: 50px 0px;
    border: 1px dashed;
    padding: 1em;
}

hr{
    margin: 50px 0px;
}

.projectPageContainer{
    max-width: 850px;
    margin-bottom: 100px;
}

