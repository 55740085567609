@font-face {
  font-family: Optima;
  src: url(../public/Optima/OptimaLTPro-Roman.otf);
}

#main{
  max-width: 1920px;
  width: 100%;
}

.App {
  background-color: #061922;
  min-height: 100vh;
  justify-content: center;
  display: flex;
}

.col-lg-3{
  position: relative;
}

.col-lg-9{
  position: relative;
}

@media only screen and (max-width: 768px){
  .col-lg-9{
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}


@media only screen and (max-width: 992px){
  .smallScreenCollapse{
    width: 0px;
  }
}
