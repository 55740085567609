header{
    color: white;
    font-size: 6em;
    letter-spacing: 0.02em;
    line-height: 1.2em;
}

#name-banner-role{
    font-size: 2em;
    color: #F39A68;
}

#name-banner-description{
    font-size: 1.25em;
    margin-top: 1em;
}